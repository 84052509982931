export default (Alpine) => {

  const requireComponent = require.context('.', false, /^(?!.*\bindex\b).*\.js$/);

  requireComponent.keys().forEach(fileName => {
    // Remove o "./" do início do nome do arquivo e a extensão .js do final
    const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, '$1');
  
    // Importa o componente
    const componentConfig = requireComponent(fileName);
  
    // Inicializa o Alpine.data com o componente
    Alpine.data(componentName, componentConfig.default || componentConfig);
  });

};