export default () => ({
    count: 10,
    button: "Increment",
    
    increment() {
        this.count++;
    },
    decrement() {
        this.count--;
    },
    reset() {
        this.count = 0;
    },
})