import * as magic from "./functions";


export default (Alpine) => {

    Object.entries(magic).forEach(([key, value]) => { Alpine.magic(key, value); });

    // Alpine.magic('now', magic.now)
    // Alpine.magic('today', magic.today)

};
