export default (Alpine) => {

    Alpine.directive('focus-parent', (el, { expression }, { evaluate }) => {
        const parentElement = el.closest(expression || '.form-group');

        el.addEventListener('change', () => {
            if (el.value !== '') {
                parentElement.classList.add('focus');
            } else {
                parentElement.classList.remove('focus');
            }
        });

        setTimeout(() => {
            if (el.value !== '') {
                parentElement.classList.add('focus');
            }
        }, 2000);

        el.addEventListener('focus', () => {
            parentElement.classList.add('focus');
        });

        el.addEventListener('blur', () => {
            if (el.value === '') {
                parentElement.classList.remove('focus');
            }
        });

    });

}