export default {
    count: 10,
    button: "Increment Test",
    increment() {
        this.count++;
    },
    decrement() {
        this.count--;
    },
    reset() {
        this.count = 0;
    },
    get total() {
        return this.count;
    },
}