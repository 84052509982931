export default () => ({
    eventos: [],

    form: {
        evento: '',
        unidade: null,
        segmento: null,
        nome_responsavel: null,
        cpf: null,
        email_responsavel: null,
        telefone_responsavel: null,
        nome_aluno: null,
        agree_lgpd: false,
        agree_policy: false,
    },

    unit_id: UNIT_INFO.id_dynamics,

    async init() {
        

    },
    
    async getSchools(){

    },

    async getEventos(unit_id) {

    },

    enviar() {
        alert("Chamei o avançar");
        console.log({...this.form}); 
    },

})