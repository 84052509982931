import Alpine from "alpinejs";

window.Alpine = Alpine;

import useComponents from "./components";
import useMagics from "./magic";
import useStore from "./store";
import useDirectives from "./directives";

document.addEventListener("alpine:init", () => {
  useComponents(Alpine);
  useMagics(Alpine);
  useStore(Alpine);
  useDirectives(Alpine);
});

Alpine.start();
